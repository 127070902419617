import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Title from "antd/es/typography/Title";
import RegionInteractiveItem from "../components/InteractiveMap/RegionInteractiveItem";
import RegionInfoModal from "../components/Modals/InteractiveMapModals/RegionInfoModal";
import BackBtn from "../components/styledComponents/BackBtn";
import InfoBtn from "../components/styledComponents/InfoBtn";
import LanguageBtn from "../components/styledComponents/LanguageBtn";
import { COLORS } from "../config/colors";
import { setLanguage } from "../features/languageSlice";
import { getAllRegionsPublic, setIsRegionOpen, setSelected, setSelectedHighlight } from "../features/regionsSlice";
import MapBaseBG from '../images/interactiveMapBase.png';
import MapBaseImg from '../images/map_base_interactive_2.png';
import { AutoComplete } from "antd";
import MainInfoPage from "../components/Modals/InteractiveMapModals/MainInfoPage";
import { REGIONS_POS } from "../local_data/regions_data";


const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-image: url(${MapBaseBG});
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	min-height: 720px;
`;

const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	
`;

const CenterContent = styled.div`
	width: 1280px;
	height: 720px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const MapWrapper = styled.div`
	width: 55%;
	position: absolute;
	top: 55%;
	transform: translate(40%, -50%);
`;

const HeaderControlsWrapper = styled.div`
	width: 1280px;
	position: relative;
	margin: 0 auto;
	z-index: 99;
`;


const MidTitles = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	position: absolute;
	top: 5px;
	right: 50%;
	transform: translateX(50%);

	.mapTitle{
		font-size: 4rem;

		@media only screen and (max-height: 835px) {
			font-size: 3rem;
		}

		${props => props.eng && css`
			font-size: 3rem;
			
			@media only screen and (max-height: 835px) {
				font-size: 2rem;
			}
		`}
	}
`;

const AutoCompleteCustom = styled(AutoComplete)`
	width: 300px;
	z-index: 99999;
	margin-top: 20px;
	font-size: 3rem;
	
	@media only screen and (max-height: 835px) {
		margin-top: 10px;
	}
`;



export default function InteractiveMap({ closeMap }) {
	const dispatch = useDispatch();

	const [isInfoOpen, setIsInfoOpen] = useState(false);
	const [selectedFromSearch, setSelectedFromSearch] = useState();

	const { items: REGIONS, isRegionOpen } = useSelector(
		(state) => state.regions
	);

	const { language } = useSelector(
		(state) => state.language
	);

	const openInfoPage = () => setIsInfoOpen(true);

	const loadRegions = useCallback(() => {
		dispatch(getAllRegionsPublic())
	}, [dispatch]);


	const toggleLanguage = () => {
		const lang = language === "he" ? "en" : "he";
		dispatch(setLanguage(lang));
	};

	const isHebrew = () => language === "he";
	const isEnglish = () => language === "en";

	const getRegionActive = (regionId) => {
		const found = REGIONS.find((region) => region.id === Number(regionId));
		if (found) {
			return found.active;
		}
		return false;
	}

	const getRegionOptions = () => {
		const filtered = REGIONS.filter((region) => {
			if (selectedFromSearch) {
				return isEnglish() ? region.nameEng.toLowerCase().includes(selectedFromSearch.toLowerCase()) : region.name.includes(selectedFromSearch);
			}
			return region.active;
		});
		return filtered.map((region) => {
			return { value: region.id.toString(), label: isEnglish() ? region.nameEng : region.name };
		})
	}

	const selectRegionHighlight = (region) => {
		dispatch(setSelectedHighlight(region));
	}


	const handleSearchSelect = (value) => {
		const regionToSelect = REGIONS.find((region) => region.id === Number(value));
		if (regionToSelect) {
			selectRegionHighlight(regionToSelect);
			setTimeout(() => {
				setSelectedFromSearch("");
			}, 10);
		}
	}

	const handleInputChange = (value) => {
		setSelectedFromSearch(value);
	}


	const closeRegion = useCallback(() => {
		dispatch(setIsRegionOpen(false))
	}, [dispatch]);



	useEffect(() => {
		if (!isRegionOpen) {
			const link = localStorage.getItem("link");
			if (link) {

				const split = link.split("|");
				setTimeout(() => {
					const buildingId = Number(split[0]);
					const region = REGIONS.find(r => r.id === buildingId);
					if (region) {
						dispatch(setSelected(region));
						split.shift();
						localStorage.setItem("link", split.join("|"));
					}
				}, 50);

			}

		}

	}, [REGIONS, dispatch, isRegionOpen])

	useEffect(() => {
		loadRegions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Wrapper>
				<HeaderControlsWrapper>
					<LanguageBtn
						style={{
							top: "30px",
							right: "50px",
						}}
						onClick={toggleLanguage}
					/>
					<BackBtn
						style={{
							top: "30px",
							left: "50px",
						}}
						onClick={closeMap}
					/>
				</HeaderControlsWrapper>
				<CenterContent>
					<InfoBtn style={{
						position: "absolute",
						bottom: "70px",
						right: "50px",
						zIndex: 99

					}}
						onClick={openInfoPage}
					/>

					<MapWrapper>
						<img
							name="map-base"
							width="100%"
							height="100%"
							src={MapBaseImg}
							alt="map-base"
						/>

						{REGIONS && REGIONS_POS.map((region) => {
							return (
								<RegionInteractiveItem
									key={region.id}
									isInMap={!isInfoOpen}
									region={{ ...region, active: getRegionActive(region.id) }}
								/>
							)
						})}
					</MapWrapper>
				</CenterContent>
				<ContentWrapper>
					<MidTitles eng={isEnglish()}>
						<div>
							{isHebrew() &&
								<Title
									className="mapTitle"
									style={{
										color: COLORS.btnBg,
										margin: 0,
										fontFamily: "IBMPlexSansHebrew-Bold"
									}}>
									גטו טרזיינשטאט
								</Title>}
							{isEnglish() &&
								<Title
									className="mapTitle"

									style={{
										color: COLORS.btnBg,
										margin: 0,
										textTransform: "uppercase",
										marginTop: "15px",
										fontFamily: "IBMPlexSansHebrew-Bold"
									}}>
									Ghetto Theresienstadt
								</Title>}
						</div>
						<div>
							{isHebrew() && <Title style={{
								color: COLORS.btnBg,
								margin: 0,
								fontSize: '2rem',
								fontFamily: "IBMPlexSansHebrew-Bold"
							}}>
								לחץ על נקודה לקבלת מידע
							</Title>}
							{isEnglish() && <Title style={{
								color: COLORS.btnBg,
								margin: 0,
								fontSize: '2rem',
								fontFamily: "IBMPlexSansHebrew-Bold"
							}}>
								Click on a site for information
							</Title>}
						</div>
						<AutoCompleteCustom
							allowClear
							dir={isEnglish() ? "ltr" : "rtl"}
							options={getRegionOptions()}
							onSelect={handleSearchSelect}
							onChange={handleInputChange}
							value={selectedFromSearch}
							placeholder={isEnglish() ? "Search a building" : "חפשו מבנה"}

						/>
					</MidTitles>


					<RegionInfoModal
						handleClose={closeRegion}
					/>
					<MainInfoPage open={isInfoOpen} handleClose={() => setIsInfoOpen(false)} />
				</ContentWrapper>
			</Wrapper>
		</>
	);
}
