import styled from '@emotion/styled';

import Icon from '../../images/site_link_icon.png';


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row-reverse;
    gap: 10px;

    :hover{
        cursor: pointer;
    }
`;

const BtnIcon = styled.div`
    width: 40px;
    height: 40px;
    background: transparent;
    background-image: url(${Icon});
    background-size: cover;
`



export default function LinkToBuildingBtn({ isEnglish, ...props }) {
    return (
        <Wrapper {...props}>
            <BtnIcon />
            <span style={{
                textDecoration: "underline",
                textTransform: "capitalize",
                fontFamily: "IBMPlexSansHebrew-Regular",
                fontSize: isEnglish ? "1.3rem" : "1.5rem"
            }}>
                {isEnglish ? "Related Locations" : "אתרים קשורים"
                }</span>
        </Wrapper>
    )
}
