import { DeleteOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import React from 'react';

export default function ButtonsRegionList({ buttons, removeButton, title, width = 200 }) {

    const handleRemoveBtn = (id) => () => {
        removeButton(id);
    }

    return (
        <div
            id="scrollableDiv"
            style={{
                height: 280,
                width,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <h4 style={{ margin: "0 auto", textAlign: "center" }}>{title}</h4>

            <List
                dataSource={buttons}
                renderItem={(btn) => (
                    <List.Item key={btn.id}>
                        <List.Item.Meta
                            title={btn.title}
                        />
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={handleRemoveBtn(btn.id)}
                        />

                    </List.Item>
                )}
            />
        </div>
    )
}
