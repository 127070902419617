import { CheckOutlined, CloseOutlined, FileOutlined, GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Switch, Watermark, message } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { setLanguage } from '../../../features/languageSlice';
import { setIsEditorGalleryOpen, setSelectedGallery, setUpdateStatus, updateRegionAsync } from '../../../features/regionsSlice';
import FileUploader from '../../FileUploader/FileUploader';
import ButtonsAssignManager from './ButtonsAssignManager';
import RegionGalleryEditorModal from './RegionGalleryEditorModal';


const RegionWrapper = styled.div`
  width: 100%;
  height: 600px;
  max-height: 650px;
  position: relative;
`;
const HeaderControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

const NamesSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  .names{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
  }
`;

const MainContentSectionWrapper = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: pre-wrap;
  gap: 35px;

  .innerGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
`;


const ButtonsSectionWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 45px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
`;

const GalleriesSectionWrapper = styled.div`
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-top: 100px;
`;

const GalleryButtonStyle = { width: '130px', height: '130px' };

export default function RegionEditorModal({
  isVisible,
  status,
  handleConfirm,
  handleClose,
  handleAfterClose,
}) {
  const dispatch = useDispatch();

  const withAutoClose = useRef(false);
  const wasGalleryEditOpen = useRef(false);

  const [active, setActive] = useState();
  const [editableName, setEditableName] = useState();
  const [editableParagraph, setEditableParagraph] = useState();

  const [editableNameEng, setEditableNameEng] = useState();
  const [editableParagraphEng, setEditableParagraphEng] = useState();

  const [photosFiles, setPhotosFiles] = useState([]);
  const [testimonialsFiles, setTestimonialsFiles] = useState([]);
  const [objectsFiles, setObjectsFiles] = useState([]);
  const [artFiles, setArtFiles] = useState([]);
  const [documentsFiles, setDocumentsFiles] = useState([]);
  const [galleryEditorFiles, setGalleryEditorFiles] = useState([]);


  const [regionBios, setRegionBios] = useState([]);
  const [regionLinks, setRegionLinks] = useState([]);

  const [coverPhoto, setCoverPhoto] = useState();

  const { selected, updateStatus, isEditorGalleryOpen } = useSelector(
    (state) => state.regions
  );

  const { language } = useSelector(
    (state) => state.language
  );



  const setUpdateRegionStatus = useCallback((status) =>
    dispatch(setUpdateStatus(status))
    , [dispatch]);

  const getFilesIds = (files) => files.map(file => file.id);

  const saveRegion = useCallback((regionData, autoClose = false) => {
    withAutoClose.current = autoClose;
    const { width, height, left, top, transform } = regionData.position;

    let finalData = {
      ...regionData,
      active,
      name: editableName,
      nameEng: editableNameEng,
      info: editableParagraph,
      infoEng: editableParagraphEng,
      width,
      height,
      left,
      top,
      rotate: transform,
      photos: photosFiles.length ? getFilesIds(photosFiles) : photosFiles,
      testimonials: testimonialsFiles.length ? getFilesIds(testimonialsFiles) : testimonialsFiles,
      objects: objectsFiles.length ? getFilesIds(objectsFiles) : objectsFiles,
      art: artFiles.length ? getFilesIds(artFiles) : artFiles,
      documents: documentsFiles.length ? getFilesIds(documentsFiles) : documentsFiles,
      bios: regionBios.length ? regionBios : [],
      links: regionLinks.length ? regionLinks : []
    }
    if (coverPhoto) {
      finalData = {
        ...finalData,
        cover: coverPhoto.id
      }
    }
    delete finalData.position;
    dispatch(updateRegionAsync(finalData));
  }, [active, artFiles, coverPhoto, dispatch, documentsFiles, editableName, editableNameEng, editableParagraph, editableParagraphEng, objectsFiles, photosFiles, regionBios, regionLinks, testimonialsFiles]);

  const selectGallery = useCallback((gallery) => {
    dispatch(setSelectedGallery(gallery));
  }, [dispatch])

  const openGalleryEditor = (gallery) => () => {

    switch (gallery) {
      case "photos":
        setGalleryEditorFiles(photosFiles);
        break;
      case "objects":
        setGalleryEditorFiles(objectsFiles);
        break;
      case "testimonials":
        setGalleryEditorFiles(testimonialsFiles);
        break;
      case "art":
        setGalleryEditorFiles(artFiles);
        break;
      case "documents":
        setGalleryEditorFiles(documentsFiles);
        break;
      default:
    }
    selectGallery(gallery);
    dispatch(setIsEditorGalleryOpen(true));
    wasGalleryEditOpen.current = true;
  }

  const closeGalleryEditor = () => {
    dispatch(setIsEditorGalleryOpen(false));
  }

  const setGalleryFiles = (gallery, files) => {
    switch (gallery) {
      case "photos":
        setPhotosFiles(files);
        break;
      case "objects":
        setObjectsFiles(files);
        break;
      case "testimonials":
        setTestimonialsFiles(files);
        break;
      case "art":
        setArtFiles(files);
        break;
      case "documents":
        setDocumentsFiles(files);
        break;
      default:
    }
  }

  const toggleLanguage = () => {
    const lang = language === "he" ? "en" : "he";
    dispatch(setLanguage(lang));
  }

  const getDirection = () => {
    return language === "he" ? "rtl" : "ltr";
  }

  const isHebrew = () => language === "he";
  const isEnglish = () => language === "en";

  const handleParagraphChange = (e) => {
    const { value } = e.target;
    if (language === "he") {
      setEditableParagraph(value);
      return;
    }
    setEditableParagraphEng(value);
  }

  const handleActiveChange = (isChecked) => {
    setActive(isChecked);
  }

  useEffect(() => {
    if (wasGalleryEditOpen.current && !isEditorGalleryOpen) {
      wasGalleryEditOpen.current = false;
      saveRegion(selected);
    }
  }, [
    isEditorGalleryOpen,
    photosFiles,
    testimonialsFiles,
    objectsFiles,
    artFiles,
    documentsFiles,
    selected,
    saveRegion
  ])


  useEffect(() => {
    switch (updateStatus) {
      case "success":
        message.success("Map region saved successfully");
        if (withAutoClose.current) {
          withAutoClose.current = false;
        }
        break;
      case "failed":
        message.error("Failed to save map region");
        break;
      default:
    }
    setUpdateRegionStatus("idle");

    return () => {

    }
  }, [setUpdateRegionStatus, updateStatus])


  useEffect(() => {
    if (isVisible && selected) {
      setActive(selected.active);
      setEditableName(selected.name);
      setEditableNameEng(selected.nameEng);
      setEditableParagraph(selected.info);
      setEditableParagraphEng(selected.infoEng);
      setCoverPhoto(selected.cover);
      setPhotosFiles(selected.photos);
      setTestimonialsFiles(selected.testimonials);
      setObjectsFiles(selected.objects);
      setArtFiles(selected.art);
      setDocumentsFiles(selected.documents);
      setRegionBios(selected.bios);
      setRegionLinks(selected.links);
    }

  }, [isVisible, selected]);

  return (
    <>
      <Modal
        keyboard
        style={{
          direction: getDirection(),
        }}
        open={isVisible}
        onOk={handleConfirm}
        onCancel={() => {

          setTimeout(handleClose, 10);

        }}
        okText={"Save"}
        cancelText={'Cancel'}
        confirmLoading={status === 'loading'}
        okButtonProps={{ type: 'primary' }}
        width={'80%'}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={updateStatus === 'loading'}
            onClick={() => saveRegion(selected, true)}
          >
            Save
          </Button>

        ]}

      >
        <div dir={getDirection()} >
          <HeaderControlsWrapper>
            <Title style={{ margin: 0 }} level={4}>{`Edit Region`}</Title>
            <Button
              onClick={toggleLanguage}
              icon={<GlobalOutlined />}
            >
              {language === "en" ? "he" : "en"}
            </Button>
            <Switch
              onChange={handleActiveChange}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />} checked={active} />
          </HeaderControlsWrapper>
          <RegionWrapper>
            <NamesSectionWrapper>
              <div className='names'>
                {isHebrew() && <Paragraph
                  strong
                  style={{ textAlign: "center", fontSize: "1rem" }}
                  editable={{
                    onChange: setEditableName,
                  }}
                >
                  {editableName}
                </Paragraph>}
                {isEnglish() && <Paragraph
                  strong
                  style={{ textAlign: "center", fontSize: "1rem" }}
                  editable={{
                    onChange: setEditableNameEng,
                  }}
                >
                  {editableNameEng}
                </Paragraph>}
              </div>
            </NamesSectionWrapper>

            <MainContentSectionWrapper>
              <ButtonsAssignManager
                links={regionLinks}
                bios={regionBios}
                isEnglish={isEnglish()}
                setLinks={setRegionLinks}
                setBios={setRegionBios}
              />


              <div className="innerGrid">
                <div>
                  {isHebrew() && <TextArea
                    style={{ whiteSpace: "pre-wrap", resize: 'none' }}
                    dir='rtl'
                    value={editableParagraph}
                    onChange={handleParagraphChange}
                    rows={15}
                    cols={70}
                    placeholder="אנא הכניסו מידע על האיזור הנבחר"
                  />}
                  {isEnglish() && <TextArea
                    style={{ whiteSpace: "pre-wrap", resize: 'none' }}
                    value={editableParagraphEng}
                    onChange={handleParagraphChange}
                    rows={15}
                    cols={60}
                    placeholder="Please enter region info"
                  />}

                </div>
                <div>
                  {coverPhoto && <img
                    src={coverPhoto.url}
                    style={{}}
                    width="600"
                    height={600 / 1.777778}
                    alt={coverPhoto.name} />}
                  <FileUploader
                    buttonText="Set cover photo"
                    fileTypes=".jpg,.png"
                    updateFile={(file) => {
                      setCoverPhoto(file);
                    }}
                    isLoading={false}
                  />

                </div>
              </div>
            </MainContentSectionWrapper>

            <GalleriesSectionWrapper>
              <Button
                onClick={openGalleryEditor("documents")}
                style={GalleryButtonStyle}
                icon={<FileOutlined />}
              >
                {language === "he" ? 'מסמכים' : "Documents"}
              </Button>
              <Button
                onClick={openGalleryEditor("art")}
                style={GalleryButtonStyle}
                icon={<FileOutlined />}
              >
                {language === "he" ? 'אומנות' : "Art"}
              </Button>
              <Button
                onClick={openGalleryEditor("objects")}
                style={GalleryButtonStyle}
                icon={<FileOutlined />}
              >
                {language === "he" ? 'חפצים' : "Objects"}
              </Button>
              <Button
                onClick={openGalleryEditor("testimonials")}
                style={GalleryButtonStyle}
                icon={<FileOutlined />}
              >
                {language === "he" ? 'קטעי עדויות' : "Testimonials"}
              </Button>
              <Button
                onClick={openGalleryEditor("photos")}
                style={GalleryButtonStyle}
                icon={<FileOutlined />}
              >
                {language === "he" ? 'תצלומים' : "Photos"}
              </Button>
            </GalleriesSectionWrapper>
            {!active && <Watermark style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999,
              cursor: 'not-allowed'
            }} content="Disabled">
              <div style={{
                height: '100%',
                backgroundColor: "rgba(100, 0, 0, 0.1)"

              }}></div></Watermark>}
          </RegionWrapper>

        </div>

      </Modal>
      <RegionGalleryEditorModal
        key={`key-${Math.random() * 1000}`}
        isVisible={isEditorGalleryOpen}
        handleClose={closeGalleryEditor}
        handleConfirm={(gallery, files) => setGalleryFiles(gallery, files)}
        items={galleryEditorFiles}
      />
    </>
  );
}
