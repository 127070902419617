import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';

import {
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
  GlobalOutlined,
  InfoOutlined,
  PlayCircleOutlined,
  VideoCameraFilled
} from '@ant-design/icons';
import { Button, Input, Popover, Tabs, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import { updateFile } from '../../../features/api/filesAPI';
import PromptDialog from '../../PromptDialog/PromptDialog';
import ButtonsAssignManager from './ButtonsAssignManager';


const FileListItem = styled.div`
  margin: 15px;
  display: inline;
  width: 10%;
  position: relative;
  border: 1px solid #000;
  /* border: ${(props) => props.border ? `2px solid ${props.border}` : ""}; */
`;

const FilePreviewHover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.2);

  transition: background-color 0.2s ease-in-out;
`;

const FileInfoWrapper = styled.div`
  width: 300px;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start;

`;

const FileInfoWrapperBottom = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

`;

function GalleryItemInfoPopover({ file, closeMe }) {
  const [itemTitle, setItemTitle] = useState(file.title);
  const [itemTitleEng, setItemTitleEng] = useState(file.titleEng);
  const [editableParagraph, setEditableParagraph] = useState(file.info);
  const [editableParagraphEng, setEditableParagraphEng] = useState(file.infoEng);

  const [fileBios, setFileBios] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);


  const [tabIndex, setTabIndex] = useState(1);


  const [lang, setLang] = useState("he");

  const onTabSelectChange = (key) => {
    setTabIndex(key);
  };

  const updateFileInfo = async () => {
    const fileTpUpdate = {
      ...file,
      title: itemTitle,
      titleEng: itemTitleEng,
      info: editableParagraph,
      infoEng: editableParagraphEng,
      bios: fileBios.length ? fileBios : [],
      links: fileLinks.length ? fileLinks : []
    }
    try {
      await updateFile(fileTpUpdate);
      message.success("File updated successfully");
      if (closeMe) closeMe();
    } catch (error) {
      message.error("File update failed " + error);
    }

  }

  const toggleLanguage = () => {
    const langToChange = lang === "he" ? "en" : "he";
    setLang(langToChange);
  }

  const isHebrew = () => lang === "he";
  const isEnglish = () => lang === "en";

  const getDirection = () => {
    return lang === "he" ? "rtl" : "ltr";
  }
  const handleTitleChange = (e) => {
    const { value } = e.target;
    if (lang === "he") {
      setItemTitle(value);
      return;
    }
    setItemTitleEng(value);
  }

  const handleParagraphChange = (e) => {
    const { value } = e.target;
    if (lang === "he") {
      setEditableParagraph(value);
      return;
    }
    setEditableParagraphEng(value);
  }

  const setLinks = useCallback(() => {
    const links = typeof file.links === "string" ? JSON.parse(file.links) : file.links;
    setFileLinks(links);
  }, [file.links]);

  const setBios = useCallback(() => {
    const bios = typeof file.bios === "string" ? JSON.parse(file.bios) : file.bios;
    setFileBios(bios);
  }, [file.bios]);

  useEffect(() => {
    setLinks();
    setBios();
  }, [file, setBios, setLinks]);


  const items = [
    {
      key: 1,
      label: isEnglish() ? 'Info' : "מידע",

    },
    {
      key: 2,
      label: isEnglish() ? 'Buttons' : "כפתורים",

    },
  ];


  return (
    <FileInfoWrapper dir={getDirection()} >
      <Button
        onClick={toggleLanguage}
        icon={<GlobalOutlined />}
        style={{ marginBottom: '15px' }}
      >
        {lang === "en" ? "he" : "en"}
      </Button>
      <Tabs
        defaultActiveKey={1}
        items={items}
        onChange={onTabSelectChange}
        size='large'
        type="card"
        centered
        tabPosition={"top"}
      />
      {tabIndex === 1 &&
        <>
          {isHebrew() && (
            <>
              <Title level={5}>שם</Title>
              <Input
                placeholder="שם המדיה"
                value={itemTitle}
                onChange={handleTitleChange}
              />
            </>
          )}
          {isEnglish() && (
            <>
              <Title level={5}>Name</Title>
              <Input placeholder="Media Name"
                value={itemTitleEng}
                onChange={handleTitleChange}
              />
            </>)}

          {isHebrew() && <>
            <Title level={5}>מידע</Title>
            <TextArea
              style={{ whiteSpace: "pre-wrap", resize: 'none' }}
              autoSize={false}
              name="he"
              value={editableParagraph}
              onChange={handleParagraphChange}
              rows={10}
              cols={20}
              placeholder="אנא הכניסו מידע על המדיה הנבחרת"
            />
          </>}
          {isEnglish() && <>
            <Title level={5}>Info</Title>
            <TextArea
              style={{ whiteSpace: "pre-wrap", resize: 'none' }}
              autoSize={false}
              name="he"
              value={editableParagraphEng}
              onChange={handleParagraphChange}
              rows={10}
              cols={20}
              placeholder="Please enter media info"
            />
          </>}
        </>}
      {tabIndex === 2 &&
        <ButtonsAssignManager
          links={fileLinks}
          bios={fileBios}
          isEnglish={isEnglish()}
          setLinks={setFileLinks}
          setBios={setFileBios}
          width="100%"
        />}
      <FileInfoWrapperBottom>
        <Button
          key="submit"
          type="primary"
          style={{ marginTop: '15px' }}
          onClick={updateFileInfo}>
          Save
        </Button>
        <Button
          key="close"
          type="primary"
          style={{ marginTop: '15px' }}
          onClick={closeMe}
          danger
        >

          Cancel
        </Button>
      </FileInfoWrapperBottom>
    </FileInfoWrapper>
  )
}

export default function EditorGalleryItem({
  item: file,
  openPreview,
  deleteItem,
  isDeleting,
  fileInfoActive,
  setFileInfoActive,
  maxLength
}) {
  const [previewHover, setPreviewHover] = useState(false);
  const [isDeletePrompt, setIsDeletePrompt] = useState(false);


  const openFileInfo = () => setFileInfoActive(file.id);
  const closeFileInfo = () => setFileInfoActive();

  const openDeletePrompt = () => setIsDeletePrompt(true);
  const closeDeletePrompt = () => setIsDeletePrompt(false);

  return (
    <>
      <FileListItem
        onMouseMove={() => setPreviewHover(true)}
        onMouseLeave={() => setPreviewHover(false)}
        border={file.border}
      >
        <Popover
          open={fileInfoActive === file.id}
          content={<GalleryItemInfoPopover
            file={file}
            closeMe={closeFileInfo}
          />
          }
          title="Media Info"
          trigger="click"
        >
          <Button
            loading={isDeleting}
            icon={
              <InfoOutlined style={{ color: '#fff', border: 'none' }} />
            }
            style={{
              zIndex: '999',
              position: 'absolute',
              left: '5px',
              top: '5px',
              color: '#fff'
            }}
            type="primary"
            onClick={openFileInfo}
          />
        </Popover>
        {file.type === 'video' && (
          <VideoCameraFilled
            style={{
              fontSize: '1.1rem',
              zIndex: '999',
              position: 'absolute',
              right: '5px',
              top: '5px',
              color: '#fff'
            }}
          />
        )}
        {file.type !== 'application' && (<img
          alt={file.name}
          width={'100%'}
          src={file.type === "image" ? file.url : file.videoThumbnail}
        />)}
        {file.type === 'application' &&
          <FilePdfOutlined style={{ fontSize: '8rem' }} />
        }
        {previewHover && (
          <FilePreviewHover>
            <Button
              icon={
                file.type === 'video' ? (
                  <PlayCircleOutlined
                    style={{ color: '#fff', border: 'none' }}
                  />
                ) : (
                  <EyeOutlined style={{ color: '#fff', border: 'none' }} />
                )
              }
              type="primary"
              onClick={openPreview}
            />
            <Button
              loading={isDeleting}
              icon={
                <DeleteOutlined style={{ color: '#fff', border: 'none' }} />
              }
              type="primary"
              danger
              onClick={openDeletePrompt}
            />


          </FilePreviewHover>

        )}
        <Title
          level={5}
          style={{
            color: "#000",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1rem"
          }}
        >
          {file.fileName}
        </Title>
      </FileListItem>
      <PromptDialog
        isOpen={isDeletePrompt}
        title={`Delete File - ${file.fileName}`}
        content={"Delete the selected file?"}
        handleClose={closeDeletePrompt}
        handleConfirm={deleteItem}
        confirmType="error"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
}
