import axios from 'axios';

//const baseURL = process.env.NODE_ENV === 'development' ? '' : '';

export function fetchAllLinks() {
  return axios.get('/links/getAll');
}

export function fetchAllFilesLinks() {
  return axios.get('/links/getAllFilesLinks');
}
export function fetchLinksById(id) {
  return axios.get('/links/', {
    params: {
      id
    }
  });
}
export function createLinksBtn(btnData) {
  return axios.post(`/links/create`, { link: btnData });
}
export function saveLinksBtn(btnData) {
  return axios.post(`/links/update`, { link: btnData });
}

export function deleteLinksBtn(id) {
  return axios.post(`/links/delete`, { id });
}
