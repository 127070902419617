import styled from '@emotion/styled';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { css, keyframes } from '@emotion/react';
import { message } from 'antd';


import MapBaseBG from '../../../images/interactiveMapBase.png';


import { setLanguage } from '../../../features/languageSlice';
import { setGalleryPdf, setSelectedGallery } from '../../../features/regionsSlice';
import { isMobile } from '../../../utils/general';
import BackBtn from '../../styledComponents/BackBtn';
import LanguageBtn from '../../styledComponents/LanguageBtn';
import ImageInfoPage from './ImageInfoPage';
import MainBuildingPage from './MainBuildingPage';



const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const Modal = styled.div`
  width:100%;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  opacity: 0;
  z-index: 999;
  pointer-events: none;
  overflow: hidden;
  background-image: url(${MapBaseBG});
  background-size: cover;
	background-repeat: no-repeat;

  ${(props) => props.wasOpen && !props.open && css`
    animation: ${fadeOut} .2s ease-in-out forwards;
    
  `}

  ${(props) => props.open && css`
  pointer-events: all;
    animation: ${fadeIn} .2s ease-in-out forwards;
  `}
`;

const MainWrapper = styled.div`
  position: relative;
  width: 1280px;//100%;
  height: 720px;//100%;
`;

const HeaderControlsWrapper = styled.div`
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  z-index: 9999;
`;

const NamesSectionWrapper = styled.div`
    width:100%;
    min-height: 80px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    /* .names{
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
    } */
`;

const PDFBooksView = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 35px;
  padding: 65px 0 65px 0;
  transform: translateX(-29%);
`;



export default function RegionInfoModal({
  isVisible,
  status,
  handleConfirm,
  handleClose,
  handleAfterClose,
}) {
  const dispatch = useDispatch();

  const modalRef = useRef(0);
  const modalOpenRef = useRef(false);
  const lastRegionId = useRef(0);
  const lastRegionGallery = useRef("");

  const { selected, isRegionOpen, isRegionGalleryOpen, selectedGallery } = useSelector(
    (state) => state.regions
  );
  const { language } = useSelector(
    (state) => state.language
  );


  const [region, setRegion] = useState();
  const [regionLoading, setRegionLoading] = useState(true);

  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  // const [isPortrait, setIsPortrait] = useState(false);
  // const [isSquare, setIsSquare] = useState(false);


  const loadRegion = async (id) => {
    setRegionLoading(true);
    try {
      const { data } = await axios.get(`/data/get?id=${id}`);
      setRegion(data);
      setRegionLoading(false);
    } catch (error) {
      setRegionLoading(false);
      message.error(`Could not load map region, code: ${error.code}`);
    }
  }


  const toggleLanguage = () => {
    const lang = language === "he" ? "en" : "he";
    dispatch(setLanguage(lang));
  };


  const getDirection = () => {
    return language === "he" ? "rtl" : "ltr";
  }

  const enableGalleryIsBook = useCallback(() => {
    dispatch(setGalleryPdf(true))
  }, [dispatch]);

  const disableGalleryIsBook = useCallback(() => {
    dispatch(setGalleryPdf(false))
  }, [dispatch]);

  const closeGallery = useCallback(() => {
    dispatch(setSelectedGallery(null));
  }, [dispatch]);

  const handleBackButton = () => {
    if (isRegionGalleryOpen && !selectedImage) {
      closeGallery();
      modalOpenRef.current = false;
      return;
    }
    if (isRegionGalleryOpen && selectedImage) {
      setSelectedImage(null);
      return;
    }

    handleClose();
  }

  const getGalleryHebHeader = () => {
    switch (selectedGallery) {
      case "photos":
        return "תמונות";
      case "objects":
        return "חפצים";
      case "testimonies":
        return "קטעי עדויות";
      case "art":
        return "אומנות"
      case "documents":
        return "מסמכים"
      default:
        return null;
    }
  }

  const getRegionItems = useCallback(
    () => {
      return region ? region[selectedGallery === "testimonies" ? "testimonials" : selectedGallery] : [];
    }
    , [region, selectedGallery]);

  const createBooksLibrary = useCallback(() => {
    let books = [];

    region["documents"]
      .forEach((img, idx) => {
        const nameSplit = img.fileName.split("-");
        const bookName = nameSplit[0];
        const pageNumber = nameSplit[1].split(".")[0];
        const existingBook = books.find((book) => book.book === bookName);
        if (!existingBook &&
          (Number(pageNumber) === 0 || Number(pageNumber)) === 1) {
          books.push({
            ...img,
            id: idx + 1,
            fileName: img.fileName,
            // type: "application",
            url: img.url,
            book: bookName,
            title: img.title,
            titleEng: img.titleEng,
            info: img.info,
            infoEng: img.infoEng,
            isCover: pageNumber === 0
          });
        }
      });
    enableGalleryIsBook();
    setGalleryItems(books);
  }, [enableGalleryIsBook, region]);

  // const checkPortrait = useCallback(() => {
  //   if (selectedImage) {
  //     const img = new Image();
  //     img.onload = function () {
  //       // console.log(`portrait: ${this.width > this.height}, width: ${this.width}, height: ${this.height}`);
  //       setIsPortrait(this.width < this.height);
  //     }
  //     img.src = selectedImage.type === "video" ? selectedImage.videoThumbnail : selectedImage.url;
  //   }
  // }, [selectedImage])

  // const checkSquare = useCallback(() => {
  //   if (selectedImage) {
  //     const img = new Image();
  //     img.onload = function () {
  //       // console.log(`square: ${this.width === this.height}, width: ${this.width}, height: ${this.height}`);
  //       setIsSquare(this.width === this.height);
  //     }
  //     img.src = selectedImage.type === "video" ? selectedImage.videoThumbnail : selectedImage.url;
  //   }
  //   return false;
  // }, [selectedImage]);

  // const getImageWidth = () => {
  //   if (isSquare) {
  //     return "auto";
  //   }
  //   return isPortrait ? "auto" : "100%";
  // }

  // const getImageHeight = () => {
  //   if (isSquare) {
  //     return "auto";
  //   }
  //   return isPortrait ? "100%" : "auto";
  // }

  useEffect(() => {
    if (selectedGallery && lastRegionGallery.current !== selectedGallery) {
      if (selectedGallery !== "documents") {
        disableGalleryIsBook();
        setGalleryItems(getRegionItems());
        return;
      }

      if (selectedGallery === "documents" && region["documents"].length) {
        createBooksLibrary();
      }

    }
    lastRegionGallery.current = selectedGallery;


  }, [disableGalleryIsBook, selectedGallery, galleryItems, region, createBooksLibrary, getRegionItems]);

  useEffect(() => {
    if (isRegionOpen && selected && lastRegionId.current !== selected.id) {
      lastRegionId.current = selected.id;
      loadRegion(selected.id);
      modalOpenRef.current = true;

      return;
    }
    lastRegionId.current = -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegionOpen]);

  // useEffect(() => {
  //   checkPortrait();
  //   checkSquare();
  // }, [checkPortrait, checkSquare, selectedImage]);


  return (
    <>
      <Modal
        ref={modalRef}
        open={isRegionOpen}
        wasOpen={modalOpenRef.current}
      >
        <div style={{ width: "100%" }} dir={getDirection()} >
          <HeaderControlsWrapper isCarousel={isRegionGalleryOpen} isMobile={isMobile()}>
            <LanguageBtn
              style={{
                position: "absolute",
                top: "30px",
                right: "50px",
                // zIndex: 99
              }}
              onClick={toggleLanguage} />
            <BackBtn
              onClick={handleBackButton}
              style={{
                position: "absolute",
                top: "30px",
                left: "50px",
                // zIndex: 99
              }}
            />
          </HeaderControlsWrapper>
          {/* {getGalleryHebHeader() && <NamesSectionWrapper >
            {isHebrew() && <Title
              level={1}
              style={{
                fontFamily: "IBMPlexSansHebrew-Bold",
                color: COLORS.btnBg,
                margin: 0,
                fontSize: '4rem'
              }}
            >
              {getGalleryHebHeader()}
            </Title>}
            {isEnglish() && <Title
              level={1}
              style={{
                fontFamily: "IBMPlexSansHebrew-Bold",
                color: COLORS.btnBg,
                margin: 0,
                fontSize: '4rem',
                textTransform: "uppercase"
              }}
            >
              {selectedGallery}
            </Title>} 
          </NamesSectionWrapper>}*/}
          <MainWrapper>

            {!isRegionGalleryOpen && <MainBuildingPage region={region} />}

            {/* {isRegionGalleryOpen && !selectedImage &&
              <ImagesCarousel
                images={getRegionItems()}
                header={getGalleryHebHeader()}
                selectForInfo={setSelectedImage}
              />} */}

            {/* {selectedGallery === "documents" && (
              <PDFBooksView>{
                galleryItems.map((item) => (
                  <PDFBooksItem
                    key={item.id}
                  >
                    {isHebrew() &&
                      <Title
                        level={1}
                        style={{
                          fontFamily: "IBMPlexSansHebrew-Bold",
                          color: COLORS.btnBg,
                          margin: 0,
                          minHeight: "35px",
                          fontSize: '1rem'
                        }}
                      >
                        {item.title}

                      </Title>}
                    {isEnglish() && <Title
                      level={1}
                      style={{
                        fontFamily: "IBMPlexSansHebrew-Bold",
                        color: COLORS.btnBg,
                        margin: 0,
                        minHeight: "35px",
                        fontSize: '1rem'
                      }}
                    >
                      {item.titleEng}

                    </Title>}
                    <img
                      width={'100%'}
                      height={'100%'}
                      src={item.url}
                      alt={item.fileName}
                    />
                  </PDFBooksItem>
                ))
              }</PDFBooksView>
            )} */}

            {isRegionGalleryOpen &&
              <ImageInfoPage
                region={region}
                allItems={galleryItems}
                regionItems={getRegionItems()}
              />
            }

          </MainWrapper>
        </div>

      </Modal >

    </>
  );
}
