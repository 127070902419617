import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllBios } from "./api/biosAPI";
import { fetchAllLinks } from "./api/linksAPI";


const initialState = {
  biosBtns: [],
  linksBtns: [],
  loadAllBiosStatus: "idle",
  loadAllLinksStatus: "idle",

};

export const getAllBios = createAsyncThunk(
  "get all bios buttons",
  async () => {
    const { data } = await fetchAllBios();
    return data;
  }
);

export const getAllLinks = createAsyncThunk(
  "get all links buttons",
  async () => {
    const { data } = await fetchAllLinks();
    return data;
  }
);




export const buttonsSlice = createSlice({
  name: "buttons",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllBios.pending, (state) => {
        state.loadAllBiosStatus = "loading";
      })
      .addCase(getAllBios.rejected, (state) => {
        state.loadAllBiosStatus = "failed";
      })
      .addCase(getAllBios.fulfilled, (state, action) => {
        state.loadAllBiosStatus = "success";
        state.biosBtns = action.payload;
      })
      .addCase(getAllLinks.pending, (state) => {
        state.loadAllLinksStatus = "loading";
      })
      .addCase(getAllLinks.rejected, (state) => {
        state.loadAllLinksStatus = "failed";
      })
      .addCase(getAllLinks.fulfilled, (state, action) => {
        state.loadAllLinksStatus = "success";
        state.linksBtns = action.payload;
      })
  }
});

export const {
  setItems,
} = buttonsSlice.actions;

export default buttonsSlice.reducer;
