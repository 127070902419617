import { configureStore } from '@reduxjs/toolkit';

import authSlice from '../features/authSlice';
import regionsReducer from '../features/regionsSlice';
import languageReducer from '../features/languageSlice';
import buttonsReducer from '../features/buttonsSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    regions: regionsReducer,
    language: languageReducer,
    buttons: buttonsReducer
  }
});
