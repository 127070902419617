import styled from "@emotion/styled";

import { Button, Modal, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageBtn from "../components/styledComponents/LanguageBtn";
import { COLORS } from "../config/colors";
import { setLanguage } from "../features/languageSlice";
import Interhost from '../images/interhost.png';
import Sponsors from '../images/sponsers.png';
import BaseBG from '../images/start.jpeg';
import Logo from '../images/start_logo.png';
import { isMobile } from "../utils/general";
import InteractiveMap from "./InteractiveMap";

import { FullscreenExitOutlined, FullscreenOutlined, ZoomInOutlined } from "@ant-design/icons";
import "../Map.css";

const RESET_TIME = 1000 * 120;

const { Text } = Typography;

const MapWrapper = styled.div`
	position: relative;
	
	/* ::-webkit-scrollbar-track {
		border-radius: 10px !important;
		background:  var(--bg-color) !important;
	}
	::-webkit-scrollbar {
		width: 12px !important;
		background-color: var(--fg-color) !important;
		border-radius: 50px !important;
	}
	::-webkit-scrollbar-thumb {
		background-color: var(--fg-color) !important;
		border-radius: 50px !important;
	} */

`;


const Wrapper = styled.div`
	/* width: 100%; */
	height: 100vh;
	overflow: hidden;
	background-image: url(${BaseBG});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	
	position: relative;
`;

const CenterContent = styled.div`
	width: 1280px;
	height: 720px;
	position: relative;
`;

const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	gap:15px;
	background-color: rgba(0,0,0,0.5);
	
	
	.start-btn{
		border:none;
		padding: 0;
		margin: 0;
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 250px;
		height: 120px;
		font-family: IBMPlexSansHebrew-Bold;
		font-size: 3rem;
		border-radius: 15px;
		background-color: ${COLORS.btnBg} ;
		color: ${COLORS.colorBrown} ;
		text-transform: uppercase;
		vertical-align: middle;
		transition: transform .2s ease-in-out ,color .1s ease-in-out,background-color .1s ease-in-out;

		:hover{
			transform: translate(-50%, -50%) scale(1.02);
			background-color: ${COLORS.colorBrown} !important;
			color: ${COLORS.btnBg} !important;
    	}
		:active{
			transform: translate(-50%, -50%) scale(1.12);
		}
	}
`;

const HeaderControlsWrapper = styled.div`
  width: ${props => props.isMobile ? "100%" : "85%"};
  position: relative;
  margin: 0 auto;
`;

const ServerSponsorWrapper = styled.div`
	width: 100%;
	//height: inherit;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 15px;
	padding :20px;
	position: fixed;
	bottom:0;
`;

const ScaleMsgWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	white-space: pre-line;
	gap: 15px;
	font-size: 1.2rem;
	text-align: center;
`;

export default function Opener() {
	const dispatch = useDispatch();
	const [modal, contextHolder] = Modal.useModal();

	const resetTimeout = useRef();
	const isShowScaleWarning = useRef(false);

	const [isMapOpen, setIsMapOpen] = useState(false);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const { language } = useSelector(
		(state) => state.language
	);

	const openMapPage = () => setIsMapOpen(true);
	const closeMapPage = () => setIsMapOpen(false);

	const toggleLanguage = () => {
		const lang = language === "he" ? "en" : "he";
		dispatch(setLanguage(lang));
	};

	const isHebrew = () => language === "he";

	// const handleUserInteraction = () => {
	// 	clearTimeout(resetTimeout.current);
	// 	resetTimeout.current = setTimeout(() => {
	// 		closeMapPage();
	// 	}, RESET_TIME);
	// }

	// useEffect(() => {
	// 	// window.addEventListener("click", handleUserInteraction);

	// 	return () => {
	// 		window.removeEventListener("click", handleUserInteraction);
	// 	};
	// 	// eslint-disable-next-line
	// }, []);

	useEffect(() => {
		if (!isMapOpen) {
			clearTimeout(resetTimeout.current);
		}


		// eslint-disable-next-line
	}, [isMapOpen]);

	const ScaleMsg = () => {
		return (
			<ScaleMsgWrapper>
				<span>{'מומלץ לצפות באתר זה כאשר הדפדפן מוגדר לזום של 100% ומטה \n'}</span>
				<span>{'It is recommended to view this website when the browser is set to 100% zoom or lower'}</span>
				<span>{'יש לחפש את האייקון מטה לשינוי הזום \n find the icon below to set zoom'}</span>
				<ZoomInOutlined style={{ fontSize: "2rem" }} />
			</ScaleMsgWrapper>)
	}

	const ShowZoomWarningModal = () => {
		let secondsToGo = 8;

		const instance = modal.warning({
			title: 'Page Zoom | זום בעמוד',
			content: <ScaleMsg style={{ whiteSpace: "pre-line" }} />,
			width: "50%",
			centered: true

		});
		const timer = setInterval(() => {
			secondsToGo -= 1;
			// instance.update({
			// 	content: `This modal will be destroyed after ${secondsToGo} second.`,
			// });
		}, 1000);
		setTimeout(() => {
			clearInterval(timer);
			instance.destroy();
		}, secondsToGo * 1000);
	};

	const openFullFrame = () => {
		const mainEl = document.getElementsByTagName("body")[0];
		if (mainEl) {
			setIsFullScreen(true);
			mainEl.requestFullscreen().catch((error) => {
				console.log(error);
			});
		}
	};

	const closeFullFrame = () => {
		setIsFullScreen(false);
		if (document.fullscreenElement) {
			document
				.exitFullscreen()
				.then(() => console.log("Document Exited from Full screen mode"))
				.catch((err) => console.error(err));
		} else {
			document.documentElement.requestFullscreen();
		}
	};


	useEffect(() => {
		if (!isShowScaleWarning.current && window.devicePixelRatio > 1) {
			isShowScaleWarning.current = true;
			ShowZoomWarningModal();
			// openScaleWarning();
			// message.config({ top: "30%" });
			// message.warning({
			// 	style: { whiteSpace: "pre-line" },
			// 	duration: 10,
			// 	content: <ScaleMsg style={{ whiteSpace: "pre-line" }} />

			// })
		}
		window.onresize = () => {

			setIsFullScreen(document.fullscreen);
		}

		return () => {
			window.onresize = null;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<MapWrapper className="main">
			{isMapOpen && <InteractiveMap closeMap={closeMapPage} />}
			{!isMapOpen && <Wrapper>
				<HeaderControlsWrapper isMobile={isMobile()}>
					<LanguageBtn
						style={{
							top: "30px",
							right: "50px",
						}}
						onClick={toggleLanguage}
					/>
					<Button style={{
						position: "absolute",
						top: 35,
						left: "50px",
						zIndex: 999
					}}
						shape="circle"
						size={"large"}
						icon={!isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
						onClick={!isFullScreen ? openFullFrame : closeFullFrame}
					/>
				</HeaderControlsWrapper>
				<ContentWrapper>
					<CenterContent>
						<img
							src={Logo}
							alt="Terezin logo"
							height={"auto"}
							style={{
								position: 'absolute',
								width: '320px',
								top: '40%',
								left: '50%',
								transform: "translate(-50%, -50%)"
							}}
						/>

						<Button className="start-btn" onClick={openMapPage}>
							{isHebrew() ? 'התחל' : "Start"}
						</Button>
					</CenterContent>
					<ServerSponsorWrapper>
						<Text strong style={{
							position: 'absolute',
							width: '100%',
							height: "15px",
							bottom: '20px',
							left: "1%",
							color: "#fff",
						}}>
							{isHebrew() ? "פרויקט זה התאפשר הודות לסיוען של: ועידת התביעות, ועדת עזבונות" : "This Project was made possible with the support of: The Claims Conference, Vaadat Ezvonot"}
						</Text>
						<div style={{ width: "100%" }}>
							<img
								src={Sponsors}
								alt="Terezin logo"
								width={500}
								style={{
									position: 'absolute',
									bottom: '20px',
									left: '0%',
								}}
							/>
						</div>
					</ServerSponsorWrapper>
					<ServerSponsorWrapper >
						<img
							src={Interhost}
							alt="Interhost logo"
							width={150}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open("https://il.interhost.co.il", "_blank");
							}}
						/>
						<Text strong style={{
							color: "#fff",
						}}>
							{isHebrew() ? "מאוחסן בסיוע\/חסות אינטרהוסט" : "This Project is hosted with the help of Interhost"}
						</Text>

					</ServerSponsorWrapper>
				</ContentWrapper>

			</Wrapper>}
			{contextHolder}
		</MapWrapper>
	);
}
