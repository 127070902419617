import React, { useCallback, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Button } from 'antd';
import Title from 'antd/es/typography/Title';

import { COLORS } from '../../../config/colors';



import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { setGalleryPdf, setSelectedGallery } from '../../../features/regionsSlice';
import TextImageDecoration from '../../../images/bottom_info_text.png';
import GalleryBtnBox from '../../../images/gallery_btn_box.png';
import GalleryBtnTextDeco from '../../../images/gallery_btn_text_deco.png';
import CoverImageBG from '../../../images/pic_box_clean.png';
import TextInfoBG from '../../../images/text_box_clean.png';
import { isMobile } from '../../../utils/general';
import PreviewBtn from '../../styledComponents/PreviewBtn';
import PreviewModal from '../PreviewModal/PreviewModal';
import { getAllBios, getAllLinks } from '../../../features/buttonsSlice';
import ExtraInfoBtn from '../../styledComponents/ExtraInfoBtn';
import BiographyModal from '../LinkButtonsModals/BiographyModal';
import LinkToBuildingBtn from '../../styledComponents/LinkToBuildingBtn';
import LinksToBuildingModal from '../LinkButtonsModals/LinksToBuildingModal';


const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: relative;
`;

const NamesSectionWrapper = styled.div`
    width: 100%;
    /* height: 60px; */
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .names{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`;

const MainContentSectionWrapper = styled.div`
    width: 1280px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;

    transform: ${props => `scale(0.8) translateX( ${props.lang === "he" ? "8%" : "-8%"})`}; 

    .innerGrid{
        width: 100%;
        height: 420px;
        display: grid;
        grid-template-columns: 53% 60%;
        position: relative;
        margin: 0 auto;
        gap: 35px;
    }
`;

const BoxBaseImg = styled.img`
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top: 0;
`

const BoxBaseCoverImg = styled.img`
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top: 0;
`

const TextAreaScrollWrapper = styled.div`
    width: 95%;
    position: absolute;
    top: 35px;
    max-height: 360px;
    height: 100%;
    /* padding-bottom: 45px;
    padding-right: 12px;*/
    padding-left: 20px; 
    overflow: hidden;
    overflow-y: auto;
`
const TextAreaCustom = styled.p`
    white-space: pre-wrap;
    font-family: IBMPlexSansHebrew-Regular;
    line-height: 35px;
    background: transparent;
    font-size: 1.6rem;
    width: 94%;
    /* padding-bottom: 45px; */
    padding-right: 35px;
    padding-left: 15px;

    ${props => props.lang === 'he' ? css`
        right: 20px;
        text-align: right;
        direction: rtl;
    ` : css`
        left: 35px;
        text-align: left;
        direction: ltr;
        width: 91.5%;
    `}

    :hover, :focus {
        background-color: transparent;
        border: none;
    }
`

const LinkButtonsWrapper = styled.div`
    width: 100%;
    height: 85px;
    z-index: 999;
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */
    padding: 0 35px;
    position: relative;
    
    ${props => props.lang === 'he' ? css`
        right: 20px;
        direction: rtl;
    ` : css`
        left: 20px;
        direction: ltr;
    `}
`;


const GalleriesSectionWrapper = styled.div`
    width: 1280px;
    height: 5%;//${props => !props.isMobile ? "200px" : "200px"};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 318px;//${props => !props.isMobile ? "315px" : "330px"};
    transform: ${props => !props.isMobile ? "scale(0.7)" : "scale(0.7)"};
`;


const GalleryButton = styled(Button)`
    width: 300px;//${props => !props.isMobile ? "400px" : "325px"};
    height: 300px;//${props => !props.isMobile ? "400px" : "325px"};
    background-color: transparent;
    /* background-image: url(${GalleryBtnBox});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .2s ease-in-out;
    position: relative;

    .btn-text{
        min-width: 250px;
        line-height: 50px;
        white-space: pre-wrap;
        font-size:  ${props => props.isMobile ? "3rem" : "3rem"};
        font-family: IBMPlexSansHebrew-Bold;
        opacity: ${props => props.disabled ? '0.5' : '1'};
        position: absolute;
        color: ${COLORS.color};
        :focus{
            color: ${COLORS.color};
            border: none;
        }

        :active {
            color: ${COLORS.btnBg} !important;
        }

        img{
            width:180px;
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    :hover{
        transform: scale(1.02);
        filter: grayscale(50%);
    }

    ${props => props.disabled && css`
        filter: brightness(50%);
    `};
`

const BoxCoverImg = styled.div`
    position: absolute;
    height: ${props => props.height};;
    width: 90%;
    background-image: ${props => props.url && `url("${encodeURI(props.url)}")`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`
const CenterContent = styled.div`
	width: 1280px;
	height: 720px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

export default function MainBuildingPage({ region }) {
    const dispatch = useDispatch();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState();
    const [previewModalKey, setPreviewModalKey] = useState('');

    const [showBioBtns, setShowBioBtns] = useState(false);
    const [showLinksBtns, setShowLinksBtns] = useState(false);

    const { language } = useSelector(
        (state) => state.language
    );

    const { selected } = useSelector(
        (state) => state.regions
    );


    const handleCancelPreview = () => setPreviewVisible(false);

    const isHebrew = () => language === "he";
    const isEnglish = () => language === "en";

    const openBiography = () => setShowBioBtns(true);
    const closeBiography = (e) => {
        e.stopPropagation();
        setShowBioBtns(false);
    }

    const openLinks = () => setShowLinksBtns(true);
    const closeLinks = (e) => {
        e?.stopPropagation();
        setShowLinksBtns(false);
    }

    const openPreview = (file) => () => {
        // if (file.type === 'application') {
        //     window.open(file.url, "_blank");
        //     return;
        // }
        setPreviewModalKey(`key-${Math.random() * 1000}`);
        setPreviewFile(file);
        setPreviewVisible(true);
    };

    const openGallery = useCallback((gallery) => {
        dispatch(setSelectedGallery(gallery));
    }, [dispatch])

    const setIsPDFGallery = useCallback((isPdf) => {
        dispatch(setGalleryPdf(isPdf));
    }, [dispatch])

    const loadAllBiosBtns = useCallback(() => {
        dispatch(getAllBios());
    }, [dispatch]);

    const loadAllLinksBtns = useCallback(() => {
        dispatch(getAllLinks());
    }, [dispatch]);

    const isShowButtons = () => region?.bios.length > 0 || region?.links.length > 0;

    const getPreviewBtnPos = () => {
        let pos = {
            bottom: "10px",

        }
        if (language === "en") {
            pos = { ...pos, right: "2.5%" };
        }

        if (language === "he") {
            pos = { ...pos, left: "2.5%" };
        }

        return pos;
    }

    const checkMobile = () => isMobile();


    useEffect(() => {
        if (selected) {
            const link = localStorage.getItem("link");
            if (link) {
                const split = link.split("|");
                const galleryName = split[0];
                setTimeout(() => {
                    if (galleryName) {
                        openGallery(galleryName);
                        split.shift();
                        if (split[0]) {
                            localStorage.setItem("link", split[0]);
                            return;
                        }
                        localStorage.removeItem("link");
                    }
                }, 50);

            }
        }



    }, [dispatch, openGallery, selected])

    useEffect(() => {
        setIsPDFGallery(false);
        loadAllBiosBtns();
        loadAllLinksBtns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Wrapper>
            <NamesSectionWrapper>
                <div className='names'>
                    {isHebrew() && <Title
                        level={2}
                        style={{
                            fontFamily: "IBMPlexSansHebrew-Bold",
                            color: COLORS.btnBg,
                            margin: 0,
                            fontSize: '4rem'
                        }}
                    >
                        {region?.name}
                    </Title>}
                    {isEnglish() && <Title
                        level={2}
                        style={{
                            fontFamily: "IBMPlexSansHebrew-Bold",
                            color: COLORS.btnBg,
                            margin: 0,
                            fontSize: '4rem'
                        }}
                    >
                        {region?.nameEng}
                    </Title>}
                </div>
            </NamesSectionWrapper>
            <CenterContent>
                <MainContentSectionWrapper isMobile={checkMobile()} lang={language}>
                    <div className="innerGrid">
                        <div style={{ position: "relative", width: "100%" }}>
                            <BoxBaseImg
                                src={TextInfoBG}
                                alt="text base"
                                width="100%"
                                height="100%"
                            />

                            {isHebrew() &&
                                <TextAreaScrollWrapper>
                                    <TextAreaCustom
                                        lang={language}
                                        dir="rtl"
                                        placeholder="לא נמצא ערך"
                                        value={region?.info}
                                    >
                                        {region?.info}
                                    </TextAreaCustom>
                                    {isShowButtons() &&
                                        <LinkButtonsWrapper lang={language}>
                                            {region?.bios.length > 0 &&
                                                <ExtraInfoBtn
                                                    style={{ position: "absolute", right: 10 }}
                                                    isEnglish={false}
                                                    onClick={openBiography}
                                                />}
                                            {region?.links.length > 0 &&
                                                <LinkToBuildingBtn
                                                    style={{ position: "absolute", left: 5 }}
                                                    isEnglish={false}
                                                    onClick={openLinks}
                                                />}
                                        </LinkButtonsWrapper>}
                                </TextAreaScrollWrapper>}
                            {isEnglish() &&
                                <TextAreaScrollWrapper>
                                    <TextAreaCustom
                                        lang={language}
                                        placeholder="No info found"
                                        value={region?.infoEng}
                                    >
                                        {region?.infoEng}
                                    </TextAreaCustom>
                                    <LinkButtonsWrapper lang={language}>
                                        {region?.bios.length > 0 &&
                                            <ExtraInfoBtn
                                                style={{ position: "absolute", right: 25 }}
                                                isEnglish={true}
                                                onClick={openBiography}
                                            />}
                                        {region?.links.length > 0 &&
                                            <LinkToBuildingBtn
                                                style={{ position: "absolute", left: 5 }}
                                                isEnglish={true}
                                                onClick={openLinks}
                                            />}
                                    </LinkButtonsWrapper>}
                                </TextAreaScrollWrapper>}

                            <img
                                src={TextImageDecoration}
                                alt="bottom text decoration"
                                style={{
                                    position: 'absolute',
                                    width: '88.8%',
                                    bottom: '25px',
                                    right: '30px',
                                    left: '30px',
                                }}
                            />
                        </div>
                        <div style={{ position: "relative", width: "100%" }}>
                            <BoxBaseCoverImg
                                src={CoverImageBG}
                                alt="cover image base"
                                lang={language}
                                width="100%"
                                height="100%"
                            />
                            <BoxCoverImg
                                url={region?.cover?.url}
                                height={"90%"}
                                alt={region?.cover?.fileName}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: "translate(-50%, -50%)"
                                }}
                            />
                            {region?.cover &&
                                <PreviewBtn
                                    onClick={openPreview(region?.cover)}
                                    style={getPreviewBtnPos()}
                                />}

                        </div>
                    </div>
                </MainContentSectionWrapper >
                <GalleriesSectionWrapper isMobile={isMobile()}>
                    <GalleryButton
                        isMobile={isMobile()}
                        onClick={() => openGallery("documents")}
                        disabled={region?.documents.length === 0}
                    >
                        <img
                            style={{ position: "absolute", height: "inherit", width: "inherit" }}
                            src={GalleryBtnBox}
                            width={"100%"}
                            height={"100%"}
                            alt="gallery-button-bg" />
                        <div className='btn-text'>
                            <span style={{ fontSize: isEnglish() ? "2.8rem" : "" }}>{language === "he" ? 'מסמכים' : "Documents"}</span>
                            <img src={GalleryBtnTextDeco} alt="gallery-btn-decoration" />
                        </div>
                    </GalleryButton>
                    <GalleryButton
                        isMobile={isMobile()}
                        onClick={() => openGallery("art")}
                        disabled={region?.art.length === 0}
                    >
                        <img
                            style={{ position: "absolute", height: "inherit", width: "inherit" }}
                            src={GalleryBtnBox}
                            width={"100%"}
                            height={"100%"}
                            alt="gallery-button-bg" />
                        <div className='btn-text'>
                            {language === "he" ? 'אומנות' : "Art"}
                            <img src={GalleryBtnTextDeco} alt="gallery-btn-decoration" />
                        </div>
                    </GalleryButton>
                    <GalleryButton
                        isMobile={isMobile()}
                        onClick={() => openGallery("objects")}
                        disabled={region?.objects.length === 0}
                    >
                        <img
                            style={{ position: "absolute", height: "inherit", width: "inherit" }}
                            src={GalleryBtnBox}
                            width={"100%"}
                            height={"100%"}
                            alt="gallery-button-bg" />
                        <div className='btn-text'>
                            {language === "he" ? 'חפצים' : "Objects"}
                            <img src={GalleryBtnTextDeco} alt="gallery-btn-decoration" />
                        </div>
                    </GalleryButton>
                    <GalleryButton
                        isMobile={isMobile()}
                        onClick={() => openGallery("testimonies")}
                        disabled={region?.testimonials.length === 0}
                    >
                        <img
                            style={{ position: "absolute", height: "inherit", width: "inherit" }}
                            src={GalleryBtnBox}
                            width={"100%"}
                            height={"100%"}
                            alt="gallery-button-bg" />
                        <div className='btn-text' >
                            <span style={{ verticalAlign: "baseline", fontSize: isEnglish() ? "2.7rem" : "" }}>{language === "he" ? 'קטעי עדויות' : "Testimonies"}</span>
                            <img src={GalleryBtnTextDeco} alt="gallery-btn-decoration" />
                        </div>
                    </GalleryButton>
                    <GalleryButton
                        isMobile={isMobile()}
                        onClick={() => openGallery("photos")}
                        disabled={region?.photos.length === 0}
                    >
                        <img
                            style={{ position: "absolute", height: "inherit", width: "inherit" }}
                            src={GalleryBtnBox}
                            width={"100%"}
                            height={"100%"}
                            alt="gallery-button-bg" />
                        <div className='btn-text'>
                            {language === "he" ? 'תצלומים' : "Photos"}
                            <img src={GalleryBtnTextDeco} alt="gallery-btn-decoration" />
                        </div>
                    </GalleryButton>

                </GalleriesSectionWrapper>
            </CenterContent>
            <PreviewModal
                modalKey={previewModalKey}
                isOpen={previewVisible}
                file={previewFile}
                handleCancel={handleCancelPreview}
                width={isMobile() ? '90%' : '50%'}
            />

            <BiographyModal
                open={showBioBtns}
                bioBtns={region?.bios || []}
                handleClose={closeBiography}
            />

            <LinksToBuildingModal
                open={showLinksBtns}
                linksBtns={region?.links || []}
                handleClose={closeLinks}
            />
        </Wrapper>
    )
}
