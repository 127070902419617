import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';


import { css } from '@emotion/react';
import { List } from 'antd';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../config/colors';
import TextInfoBG from '../../../images/info_box_bio.png';
import CloseX from '../../../images/x_close.png';

import MapBaseBG from '../../../images/interactiveMapBase.png';

const Wrapper = styled.div`
    background-image: url(${MapBaseBG});
    background-size: cover;
	background-repeat: no-repeat;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    z-index: 99999;

    ${props => props.open && css`
        pointer-events: all;
        opacity: 1;
    `}
`;

const BlackBase = styled.div`
    background-color: transparent;//rgba(0,0,0,0.7);
    cursor: pointer;
    position: absolute;
    top: 0;
    left:0;
    bottom:0;
    right: 0;
`;

const CenterContent = styled.div`
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainContentSectionWrapper = styled.div`
    width: 679px;
    height: 434px;
    background-image: url(${TextInfoBG});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const BioContentWrapper = styled.div`
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    padding: 30px;
    position: relative;
`;

const TextAreaWrapper = styled.div`
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
`

const TextAreaCustom = styled.p`//styled(TextArea)
    white-space: pre-wrap;
    font-family: IBMPlexSansHebrew-Regular;
    line-height: 25px;
    background: transparent;
    font-size: 1.2rem;
    padding-top: 15px;
    text-align: center;
`

const RectangleBullet = styled.div`
    border: 1px solid ${COLORS.colorBrown};
    width: 17px;
    height: 17px;
    margin: 0;
    padding: 0;
`;


export default function BiographyModal({ open, handleClose, bioBtns }) {
    // const dispatch = useDispatch();

    const { language } = useSelector(
        (state) => state.language
    );

    const [title, setTitle] = useState("");
    const [titleEng, setTitleEng] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [bodyTextEng, setBodyTextEng] = useState("");

    const [singleMode, setSingleMode] = useState(false);

    // const toggleLanguage = () => {
    //     const lang = language === "he" ? "en" : "he";
    //     dispatch(setLanguage(lang));
    // };

    const isHebrew = () => language === "he";
    const isEnglish = () => language === "en";

    const closeState = (e) => {
        if (singleMode) {
            if (bioBtns.length > 1) {
                setSingleMode(false);
                return;
            }
        }
        handleClose(e);
    }

    const openBio = (bioId) => () => {
        console.log(bioId);
        const foundBio = bioBtns.find((bioBtn) => bioBtn.id === bioId);

        if (foundBio) {
            setTitle(foundBio.title);
            setTitleEng(foundBio.titleEng);
            setBodyText(foundBio.info);
            setBodyTextEng(foundBio.infoEng);
            setSingleMode(true);
        }
    }


    useEffect(() => {
        if (bioBtns && bioBtns.length === 1) {
            setTitle(bioBtns[0].title);
            setTitleEng(bioBtns[0].titleEng);
            setBodyText(bioBtns[0].info);
            setBodyTextEng(bioBtns[0].infoEng);
            setSingleMode(true);
        }
    }, [bioBtns]);


    return (
        <Wrapper
            open={open}
            direction={isHebrew() ? "rtl" : "ltr"}
        >

            <BlackBase onClick={handleClose} />

            <CenterContent>
                <img
                    src={CloseX} alt="close-biography"
                    onClick={closeState}
                    style={{
                        cursor: "pointer",
                        position: 'absolute',
                        top: 25,
                        left: 30,
                        zIndex: 1000
                    }}
                />
                <MainContentSectionWrapper >
                    <BioContentWrapper>
                        {singleMode &&
                            <> <Title
                                level={3}
                                style={
                                    {
                                        margin: 0,
                                        padding: 0,
                                        zIndex: 999,
                                        fontFamily: "IBMPlexSansHebrew-Bold"
                                    }
                                }
                            >
                                {isHebrew() ? title : titleEng}
                            </Title>
                                <TextAreaWrapper>
                                    {isHebrew() &&
                                        <TextAreaCustom lang={language}>{bodyText}</TextAreaCustom>
                                    }
                                    {isEnglish() && <TextAreaCustom lang={language}>{bodyTextEng}</TextAreaCustom>

                                    }
                                </TextAreaWrapper>
                            </>}
                        {!singleMode && <TextAreaWrapper>
                            <List
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "0 25px"
                                }}
                                dataSource={bioBtns}
                                renderItem={(item) => (
                                    <List.Item
                                        style={{
                                            alignSelf: "flex-start",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "10px",
                                            fontSize: "1.5rem",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            fontFamily: "IBMPlexSansHebrew-Regular"
                                        }}
                                        onClick={openBio(item.id)}
                                    >
                                        <RectangleBullet />
                                        {item.title}
                                    </List.Item>
                                )}
                            />
                        </TextAreaWrapper>}
                    </BioContentWrapper>
                </MainContentSectionWrapper>
            </CenterContent>
        </Wrapper>
    )
}
