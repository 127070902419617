import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, message, Popover, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchAllBios } from '../../../features/api/biosAPI';
import { fetchAllLinks } from '../../../features/api/linksAPI';
import ButtonsRegionList from './ButtonsRegionList';

// const ButtonsPreviewSectionWrapper = styled.div`
//   width: 100%;
//   /* height: 50px; */
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   gap: 35px;
//   position: absolute;
// `;
export default function ButtonsAssignManager({
    bios, links, setLinks, setBios, isEnglish, width = ""
}) {

    const [isAddBios, setIsAddBios] = useState(false);
    const [isLoadingBios, setIsLoadingBios] = useState(false);
    const [loadedBioButtons, setLoadedBioButtons] = useState([]);
    const [regionBioSearch, setRegionBioSearch] = useState();

    const [isAddLinks, setIsAddLinks] = useState(false);
    const [isLoadingLinks, setIsLoadingLinks] = useState(false);
    const [regionLinksSearch, setRegionLinksSearch] = useState();
    const [loadedLinksButtons, setLoadedLinksButtons] = useState([]);


    const [fullDataBiosBtns, setFullDataBiosBtns] = useState([]);
    const [fullDataLinksBtns, setFullDataLinksBtns] = useState([]);


    const openAddBios = () => setIsAddBios(true);
    const closeAddBios = () => setIsAddBios(false);

    const openAddLinks = () => setIsAddLinks(true);
    const closeAddLinks = () => setIsAddLinks(false);



    const handleRegionBioInputChange = (value) => {
        setRegionBioSearch(value);
    }

    const handleRegionBioRemove = (id) => {
        const updatedButtons = [...fullDataBiosBtns.filter(bio => bio.id !== id)];
        setFullDataBiosBtns(updatedButtons);
        if (updatedButtons.length > 0) {
            setBios([updatedButtons.map(bio => bio.id)]);
            return;
        }
        setBios([]);
    }


    const handleRegionBioSelect = (value) => {
        const existingBio = bios.find((bio) => bio.id === Number(value));
        if (existingBio) {
            message.warning("Bio button already used")
            return;
        }
        setBios([...bios, Number(value)]);
        closeAddBios();
        setTimeout(() => {
            setRegionBioSearch("");
        }, 10);
        // const bioToSet = loadedBioButtons.find((bio) => bio.id === Number(value));

        // if (bioToSet) {
        //     closeAddBios();

        // }
    }



    const handleRegionLinkSelect = (value) => {
        const existingBio = links.find((link) => link.id === Number(value));
        if (existingBio) {
            message.warning("Link button already used")
            return;
        }
        setLinks([...links, Number(value)]);
        closeAddLinks();
        setTimeout(() => {
            setRegionLinksSearch("");
        }, 10);
        // const bioToSet = loadedLinksButtons.find((link) => link.id === Number(value));
        // if (bioToSet) {
        //     closeAddLinks();

        // }
    }

    const handleRegionLinksInputChange = (value) => {
        setRegionLinksSearch(value);
    }


    const handleRegionLinkRemove = (id) => {
        const updatedButtons = [...fullDataLinksBtns.filter(link => link.id !== id)];
        setFullDataLinksBtns(updatedButtons);
        if (updatedButtons.length > 0) {
            setLinks([updatedButtons.map(link => link.id)]);
            return;
        }
        setLinks([]);
    }

    const getLoadedRegionBios = () => {
        if (loadedBioButtons) {
            return loadedBioButtons
                .map((bio) => {
                    return {
                        value: bio.id.toString(),
                        label: isEnglish ? bio.titleEng : bio.title
                    };
                })
        }
        return [];
    }

    const getLoadedRegionLinks = () => {
        if (loadedLinksButtons) {
            return loadedLinksButtons.map((link) => {
                return {
                    value: link.id.toString(),
                    label: isEnglish ? link.titleEng : link.title
                };
            })
        }
        return [];
    }

    const handleAddBiosOpenChange = (newOpen) => {
        setIsAddBios(!newOpen);
    };

    const loadAllBios = async () => {
        try {
            setIsLoadingBios(true);
            const { data } = await fetchAllBios();
            setIsLoadingBios(false);
            setLoadedBioButtons(data);
        } catch (error) {
            setIsLoadingBios(false);
            message.error("Failed to load bios");
        }
    }

    const loadAllLinks = async () => {
        try {
            setIsLoadingLinks(true);
            const { data } = await fetchAllLinks();
            setIsLoadingLinks(false);
            setLoadedLinksButtons(data);
        } catch (error) {
            setIsLoadingLinks(false);
            message.error("Failed to load bios");
        }
    }

    const onTabSelectChange = () => {
        closeAddBios();
        closeAddLinks();
    }

    const isBiosNumbers = useCallback(() => bios.every(bios => typeof bios === "number"), [bios]);
    const isLinksNumbers = useCallback(() => links.every(bios => typeof bios === "number"), [links]);

    useEffect(() => {
        if (loadedBioButtons.length) {
            const fullBiosButtons = bios.map((bioId) => {
                const btnToSet = loadedBioButtons.find((btn) => btn.id === Number(bioId));
                if (btnToSet) {
                    return btnToSet;
                }
            });
            setFullDataBiosBtns(fullBiosButtons);
            return;
        }
        setFullDataBiosBtns(bios);
    }, [bios, isBiosNumbers, loadedBioButtons]);

    useEffect(() => {
        if (loadedLinksButtons.length) {

            const fullLinksButtons = links.map((bioId) => {
                const btnToSet = loadedLinksButtons.find((btn) => btn.id === Number(bioId));
                if (btnToSet) {
                    return btnToSet;
                }
            });
            setFullDataLinksBtns(fullLinksButtons);
            return;
        }
        setFullDataLinksBtns(links);
    }, [links, isLinksNumbers, loadedLinksButtons]);

    useEffect(() => {
        loadAllLinks();
        loadAllBios();
    }, [bios, links]);

    return (
        <Tabs
            centered
            size='large'
            type="card"
            onChange={onTabSelectChange}
            tabPosition={"top"}
            style={{ width }}
            items={[
                {
                    key: '1',
                    label: 'Biography',
                    children: <>
                        {!isLoadingBios && <ButtonsRegionList
                            title="Bio Buttons"
                            buttons={fullDataBiosBtns}
                            removeButton={handleRegionBioRemove}
                            width={width}
                        />}
                        <Popover
                            open={isAddBios}
                            content={<AutoComplete
                                allowClear
                                dir={isEnglish ? "ltr" : "rtl"}
                                options={getLoadedRegionBios()}
                                onSelect={handleRegionBioSelect}
                                onSearch={handleRegionBioInputChange}
                                value={regionBioSearch}
                                filterOption={(inputValue, option) =>
                                    isEnglish ?
                                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
                                        option.label.indexOf(inputValue) !== -1
                                }
                                placeholder={isEnglish ? "Search buttons" : "חפשו בכפתורים"}

                            />
                            }
                            title="Add Bio Buttons"
                            onOpenChange={handleAddBiosOpenChange}
                        >
                            {!isAddBios && <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={openAddBios}
                                loading={isLoadingBios}
                            >
                                Add Bio
                            </Button>
                            }
                            {isAddBios && <Button
                                onClick={closeAddBios}
                                type="primary"
                                danger
                                icon={<CloseOutlined />}
                            >
                                Cancel
                            </Button>}
                        </Popover>
                    </>,
                },
                {
                    key: '2',
                    label: 'Links',
                    children: <>
                        {!isLoadingLinks && <ButtonsRegionList
                            title="Link Buttons"
                            buttons={fullDataLinksBtns}
                            removeButton={handleRegionLinkRemove}
                            width={width}
                        />}
                        <Popover
                            open={isAddLinks}

                            content={<AutoComplete
                                allowClear
                                dir={isEnglish ? "ltr" : "rtl"}
                                options={getLoadedRegionLinks()}
                                onSelect={handleRegionLinkSelect}
                                onSearch={handleRegionLinksInputChange}
                                value={regionLinksSearch}
                                filterOption={(inputValue, option) =>
                                    isEnglish ?
                                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
                                        option.label.indexOf(inputValue) !== -1
                                }
                                placeholder={isEnglish ? "Search buttons" : "חפשו בכפתורים"}

                            />
                            }
                            title="Add Bio Buttons"
                            onOpenChange={handleAddBiosOpenChange}
                        >
                            {!isAddLinks && <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={openAddLinks}
                                loading={isLoadingLinks}
                            >
                                Add Link
                            </Button>
                            }
                            {isAddLinks && <Button
                                onClick={closeAddLinks}
                                type="primary"
                                danger
                                icon={<CloseOutlined />}
                            >
                                Cancel
                            </Button>}
                        </Popover>
                    </>,
                },

            ]} />
    )
}
