import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchInterfaces as fetchMapRegions,
  fetchRegionFiles as fetchRegionCategoryFiles,
  saveRegion as saveMapRegion
} from "./api/regionsAPI";
import axios from "axios";


const initialState = {
  items: [],
  categoryFiles: [],
  loadAllRegionsStatus: "idle",
  loadRegionStatus: "idle",
  updateStatus: "idle",
  selected: null,
  selectedHighlight: null,
  selectedGallery: null,
  isEditorOpen: false,
  isEditorGalleryOpen: false,
  isRegionOpen: false,
  isRegionGalleryOpen: false,
  isRegionGalleryPdf: false
};

export const getAllRegions = createAsyncThunk(
  "regions get all",
  async () => {
    const { data } = await fetchMapRegions();
    return data;
  }
);

export const getAllRegionsPublic = createAsyncThunk(
  "regions get all public",
  async () => {
    const { data } = await axios.get('/data/get');
    return data;
  }
);

export const getRegionById = createAsyncThunk(
  "regions get region",
  async (id) => {
    const { data } = await getRegionById(id);
    return data;
  }
);



export const loadCategoryFilesAsync = createAsyncThunk(
  "region get category files",
  async (files) => {
    const { data } = await fetchRegionCategoryFiles(files);
    return data;
  }
);

export const updateRegionAsync = createAsyncThunk(
  "regions update",
  async (dataObj) => {
    const { data } = await saveMapRegion(dataObj);
    return data;
  }
);

export const interfacesSlice = createSlice({
  name: "regions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearSelections: (state, action) => {
      state.selectedGallery = null;
      state.selected = null;
      state.isRegionGalleryOpen = false;
      state.isRegionOpen = false;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setSelected: (state, action) => {
      let region = { ...action.payload };
      let fullRegion = state.items.find(item => item.id === region.id);
      if (fullRegion) {
        state.selected = fullRegion;
        state.isRegionOpen = true;
        return;
      }
      state.selected = region;
      state.isRegionOpen = true;
    },
    setSelectedHighlight: (state, action) => {
      state.selectedHighlight = action.payload;
    },
    setSelectedGallery: (state, action) => {
      state.selectedGallery = action.payload;
      state.isRegionGalleryOpen = false;
      if (action.payload) {
        state.isRegionGalleryOpen = true;
      }
      else {
        state.selected = null;
      }
    },
    setGalleryPdf: (state, action) => {
      state.isRegionGalleryPdf = action.payload;
    },
    setUpdateStatus: (state, action) => {
      state.updateStatus = action.payload;
    },
    setLoadItemsStatus: (state, action) => {
      state.loadRegionStatus = action.payload;
    },
    setIsEditorOpen: (state, action) => {
      state.isEditorOpen = action.payload;
    },
    setIsEditorGalleryOpen: (state, action) => {
      state.isEditorGalleryOpen = action.payload;
    },
    setIsRegionOpen: (state, action) => {
      state.isRegionOpen = action.payload;
    },
    setIsRegionGalleryOpen: (state, action) => {
      state.isRegionGalleryOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllRegions.pending, (state) => {
        state.loadAllRegionsStatus = "loading";
      })
      .addCase(getAllRegions.rejected, (state) => {
        state.loadAllRegionsStatus = "failed";
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.loadAllRegionsStatus = "success";
        state.items = action.payload;
      })
      .addCase(getAllRegionsPublic.pending, (state) => {
        state.loadAllRegionsStatus = "loading";
      })
      .addCase(getAllRegionsPublic.rejected, (state) => {
        state.loadAllRegionsStatus = "failed";
      })
      .addCase(getAllRegionsPublic.fulfilled, (state, action) => {
        state.loadAllRegionsStatus = "success";
        state.items = action.payload;
      })

      .addCase(loadCategoryFilesAsync.pending, (state) => {
        state.loadCategoryFilesStatus = "loading";
      })
      .addCase(loadCategoryFilesAsync.rejected, (state, action) => {
        state.loadCategoryFilesStatus = "failed";

      })
      .addCase(loadCategoryFilesAsync.fulfilled, (state, action) => {
        state.loadCategoryFilesStatus = "success";
        state.categoryFiles = action.payload;
      })
      .addCase(updateRegionAsync.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateRegionAsync.rejected, (state) => {
        state.updateStatus = "failed";
      })
      .addCase(updateRegionAsync.fulfilled, (state, action) => {
        state.updateStatus = "success";
        state.items = action.payload;
      })

  }
});

export const {
  clearSelections,
  setItems,
  setSelected,
  setSelectedHighlight,
  setSelectedGallery,
  setUpdateStatus,
  setLoadItemsStatus,
  setIsEditorOpen,
  setIsEditorGalleryOpen,
  setIsRegionOpen,
  setIsRegionGalleryOpen,
  setGalleryPdf
} = interfacesSlice.actions;

export default interfacesSlice.reducer;
