import styled from '@emotion/styled';

import InfoIcon from '../../images/info_icon.png';


const Btn = styled.button`
    width: 80px;
    height: 80px;
    background: transparent;
    background-image: url(${InfoIcon});
    background-size: cover;
    border: none;
    position: absolute;
    bottom: -35px;
    cursor: pointer;
    transition: transform .2s ease-in-out;

    :hover{
        background: none;
        background-image: url(${InfoIcon});
        background-size: cover;
        transform: scale(1.02);
        filter: grayscale(50%);
    }
    :focus {
        border: none;
    }
`


export default function InfoBtn({ ...props }) {
    return (
        <>
            <Btn {...props} />
        </>
        //     <Icon component={IconSVG} style={{ width: '100%', height: '100%' }} />
        // </Btn>
    )
}
